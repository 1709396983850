.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50vh;
}

.Spinner img {
  width: 150px;
}